import { Store } from 'redux';
import { CLEAR_STATE } from '../context/types';

export const logoutActions = (
  removeCookie: (
    name: 'jeep_bearer' | 'jeep_country' | 'jeep_authenticated',
    options?: any
  ) => void,
  store: Store
) => {
  removeCookie('jeep_country', { path: '/' });
  removeCookie('jeep_authenticated', { path: '/' });
  removeCookie('jeep_bearer', { path: '/' });
  store.dispatch({ type: CLEAR_STATE });
};
