import React, {
  createContext,
  useContext,
  useState,
  Fragment,
  useRef,
  useEffect,
  PropsWithChildren
} from 'react';
import { Transition } from '@headlessui/react';
import { CheckCircleIcon } from '@heroicons/react/outline';
import { XCircleIcon } from '@heroicons/react/solid';
import { BanIcon } from '@heroicons/react/solid';
import { useTranslation } from 'react-i18next';

interface AlertOptions {
  text?: string;
  delay?: number;
}

const AlertContext = createContext({
  show: (message: string, option?: AlertOptions) => {},
  error: (message: string, option?: AlertOptions) => {},
  close: () => {}
});

const DEFAULT_DELAY = 10_000;

const AlertProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [text, setText] = useState('');
  const [message, setMessage] = useState('');
  const [isError, setIsError] = useState(false);
  const timerRef = useRef<any>(null);
  const { t } = useTranslation();

  useEffect(() => {
    return () => {
      if (timerRef && timerRef.current) {
        clearTimeout(timerRef.current);
      }
    };
  }, []);

  const close = () => {
    setText('');
    setMessage('');
    setIsError(false);
  };

  const show = (message: string, options?: AlertOptions) => {
    const { text, delay = DEFAULT_DELAY } = options || {};
    setMessage(message);
    setText(text || '');
    setIsError(false);
    setTimer();
  };

  const error = (message: string, options?: AlertOptions) => {
    const { text, delay = DEFAULT_DELAY } = options || {};
    setMessage(message);
    setText(text || '');
    setIsError(true);
    setTimer();
  };

  const setTimer = (delay = DEFAULT_DELAY) => {
    if (timerRef && timerRef.current) {
      clearTimeout(timerRef.current);
    }
    timerRef.current = setTimeout(close, delay);
  };

  return (
    <AlertContext.Provider value={{ show, close, error }}>
      {children}
      <div
        aria-live="assertive"
        className="fixed inset-0 flex items-end px-4 py-6 pointer-events-none sm:p-6"
      >
        <div className="w-full flex flex-col items-center space-y-4 sm:items-start">
          {/* Notification panel, dynamically insert this into the live region when it needs to be displayed */}
          <Transition
            show={!!message}
            enter="transform ease-out duration-300 transition"
            enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
            enterTo="translate-y-0 opacity-100 sm:translate-x-0"
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="max-w-sm w-full bg-white shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden mx-auto">
              <div className="p-4">
                <div className="flex items-start">
                  <div className="flex-shrink-0">
                    {isError ? (
                      <BanIcon
                        className="h-6 w-6 text-red-400"
                        aria-hidden="true"
                      />
                    ) : (
                      <CheckCircleIcon
                        className="h-6 w-6 text-green-400"
                        aria-hidden="true"
                      />
                    )}
                  </div>
                  <div className="ml-3 w-0 flex-1 pt-0.5">
                    <p className="text-sm font-bold text-gray-900">{message}</p>
                    {text && (
                      <p className="mt-1 text-sm text-gray-500">{text}</p>
                    )}
                  </div>
                  <div className="ml-4 flex-shrink-0 flex">
                    <button
                      className="bg-white rounded-md inline-flex text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                      onClick={close}
                    >
                      <span className="sr-only">{t('CANCEL')}</span>
                      <XCircleIcon className="h-5 w-5" aria-hidden="true" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Transition>
        </div>
      </div>
    </AlertContext.Provider>
  );
};

const useAlert = () => useContext(AlertContext);

export { AlertProvider, useAlert };
